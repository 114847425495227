.returns-action-btn {
    padding: 5px !important;
}

.returns-action-btn .btn {
    padding: 5px;
    font-size: 15px;
}
.ret_table td {
    padding: 2px;
    border: 0;
}
.ret_table th {
    border:0 !important;
}
table.table.ret_table {
    border: 1px solid #d7d6d6;
    border-radius: 18px !important;
    border-collapse: inherit;
    padding-bottom: 10px;
}
.custom_badge {
    background-color: red;
    color: white;
    padding: 0px 6px;
    text-align: center;
    border-radius: 5px;
    margin-right: 4px;
    font-size: 12px;
    border: 1px solid red;
    border-radius: 50%;
  }
  .confirm2 {
    border-radius: 0.25rem;
    border-width: 1px !important;
    margin: 0px;
    padding: 10px!important;
    background-color: white  !important;
    width: 47%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .deny1 {
    border-radius: 0.25rem;
    border-width: 0px !important;
    margin: 0px;
    padding: 10px!important;
    background-color: #ff8886!important;
    width: 47%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .viewitems1 {
    border-radius: 0.25rem;
    border-width: 0px !important;
    margin: 0px;
    margin-bottom: 10px;
    padding: 10px!important;
    background-color: #fbbe01!important;
    width: 100%;
  }
  td{
    padding-top: 20px!important;
    
     
  
  }
  .setPad{
    padding-top: 0.75rem!important;
  }
  .flex20{
    display: flex;
    gap: 7%;
    justify-content: center;
  }